import React from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import {
  Box,
  MenuList,
  Flex,
  Text,
  Heading,
  Button,
  Image,
  Container,
  Grid,
  GridItem,
  Stack,
  VStack,
  HStack,
  Input,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Progress,
  ChakraProvider,
  ColorModeScript,
  MenuItem,
} from "@chakra-ui/react";
import img1 from "../Assests/banner.png";
import img2 from "../Assests/face icon.png";
import img3 from "../Assests/lock icon.png";
import { FaShareAlt } from "react-icons/fa";
import img4 from "../Assests/icon1.png";
import img5 from "../Assests/icon2.png";
import img6 from "../Assests/icon3.png";
import img7 from "../Assests/icon4.png";
import img8 from "../Assests/icon5.png";
import img9 from "../Assests/icon6.png";
import img13 from "../Assests/cal.png";
import img14 from "../Assests/selfie.png";
import img15 from "../Assests/img.png";
import img16 from "../Assests/logo.png";
import img17 from "../Assests/icon_accuracy.png";
import img18 from "../Assests/icon_secure.png";
import "./Home.css";
import { Link } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Box
        width={"100vw"}
        height={["100vh", "110vh", "90vh", "83vh"]}
        backgroundColor={"#F0F1F5"}
      >
        <HStack
          height={"100%"}
          width={["80%", "80%", "90%", "80%"]}
          margin={"auto"}
        >
          <Box width={["100%", "80%", "40%", "50%"]} height={"60%"}>
          <Heading fontFamily={'Inter'} fontWeight={["600", "600", "none", "none"]}>
  Share Event Photos <br/>using Face Recognition
</Heading>


            <Text color={"#4F5158"} marginTop={"4vh"} fontSize={"1.4rem"}>
              Guests click a selfie and find their photos instantly
            </Text>

            <Stack
              direction={["column", "column", "column", "row"]}
              width={"100%"}
              marginTop={"2vh"}
            >
              <Box
                width={["100%", "100%", "100%", "32%"]}
                backgroundColor={"white"}
                borderRadius={"6px"}
              >
                <HStack justifyContent={"space-between"} padding={"10px"}>
                  <Image src={img2}></Image>
                  <Text width={"50%"} color={"#1C1E21"} fontSize={"1.1rem"}>
                    Face Recognition
                  </Text>
                </HStack>
              </Box>
              <Box
                width={["100%", "100%", "100%", "32%"]}
                backgroundColor={"white"}
                borderRadius={"6px"}
              >
                <HStack justifyContent={"space-between"} padding={"10px"}>
                  <Image src={img3}></Image>
                  <Text width={"50%"} color={"#1C1E21"} fontSize={"1.2rem"}>
                    Privacy First
                  </Text>
                </HStack>
              </Box>
              <Box
                width={["100%", "100%", "100%", "32%"]}
                height={"80%"}
                backgroundColor={"white"}
                borderRadius={"6px"}
              >
                <HStack justifyContent={"space-between"} padding={"10px"}>
                  <IconButton
                    variant="ghost"
                    aria-label="Share"
                    icon={<FaShareAlt />}
                  />
                  <Text width={"50%"} color={"#1C1E21"} fontSize={"1.2rem"}>
                    Share Photos
                  </Text>
                </HStack>
              </Box>
            </Stack>

            <Button
              marginTop={"5vh"}
              fontWeight={"200"}
              backgroundColor={"#3F115B"}
              color={"white"}
              _hover={{
                color: "black",
                border: "2px solid #3F115B",
                backgroundColor: "white",
              }}
              onClick={() => {
                navigate("/newevent");
              }}
            >
              Create New Event
            </Button>
          </Box>

          <Box
            width={["50%", "50%", "60%", "50%"]}
            margin={"auto 0px"}
            display={["none", "none", "block", "block"]}
            height={["100%", "100%", "65%", "100%"]}
          >
            <Image src={img1} width={"100%"} height={"100%"}></Image>
          </Box>
        </HStack>
      </Box>

      <Box
        width={["100vw", "100vw", "80vw", "80vw"]}
        height={["240vh", "230vh", "140vh", "110vh"]}
        margin={"0px auto"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Heading
          fontSize={["1.5rem", "1.5rem", "2rem", "3rem"]}
          fontWeight={["600", "600", "none", "none"]}
                    margin={"0px auto"}
          marginTop={"5vh"}
          textAlign={"center"}
          color={'#3F115B'}
        >
          Work For All Type Of Events Of Any Size
        </Heading>

        <Text
          fontSize={"1.3rem"}
          color={"#4F5158"}
          width={["80%", "80%", "none", "none"]}
          marginTop={["2vh", "2vh", "none", "none"]}
          textAlign={"center"}
        >
          Picslux works anywhere there are photos and people
        </Text>

        <Flex
          flexDirection={"column"}
          gap={8}
          width={["80%", "80%", "100%", "80%"]}
          margin={"0px auto"}
          height={"100%"}
          marginTop={"4vh"}
        >
          <Stack
            direction={["column", "column", "row", "row"]}
            width={"100%"}
            height={"33%"}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "70%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img4} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Weddings
                </Heading>
                <Text color={"white"} marginTop={"0.9vh"}>
                  Delight wedding guests by sharing wedding photos with them
                </Text>
                <Link to={"/Wedding"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>

            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "60%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img5} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Marathons, Cyclothons and Triathlons
                </Heading>
                <Text
                  color={"white"}
                  marginTop={"0.9vh"}
                
                >
                  Participants get instant access to their photos by clicking a
                  selfie
                </Text>
                <Link to={"/marathon"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                  
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Stack>

          <Stack
            direction={["column", "column", "row", "row"]}
            width={"100%"}
            height={"33%"}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "60%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img6} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Convocations
                </Heading>
                <Text
                  color={"white"}
                  marginTop={"0.9vh"}
                
                >
                  Delight wedding guests by sharing wedding photos with them
                </Text>
                <Link to={"/convocation"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                  
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>

            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "60%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img7} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Schools and colleges
                </Heading>
                <Text
                  color={"white"}
                  marginTop={"0.9vh"}
                
                >
                  Delight student and parents by sharing school-year photos
                </Text>
                <Link to={"/school"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                  
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Stack>
          <Stack
            direction={["column", "column", "row", "row"]}
            width={"100%"}
            height={"33%"}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "60%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img8} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Corporate Events
                </Heading>
                <Text
                  color={"white"}
                  marginTop={"0.9vh"}
                
                >
                  From conferences to team retreats, delight your participants.
                </Text>
                <Link to={"/event"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                  
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>

            <Flex
              flexDirection={"column"}
              width={["100%", "80%", "43%", "43%"]}
              height={["50%", "60%", "70%", "100%"]}
              boxShadow={"0px 30px 70px rgba(0, 9, 40, 0.12)"}
              backgroundColor={"#3F115B"}
              borderRadius={"7px"}
              p={5}
            >
              <Box width={"20%"}>
                <Image src={img9} width={"100%"}></Image>
              </Box>

              <Box>
                <Heading
                  fontWeight={"500"}
                  fontSize={"1.2rem"}
                  color={"#FFAC30"}
                >
                  Social Club
                </Heading>
                <Text
                  color={"white"}
                  marginTop={"0.9vh"}
                
                >
                  Let participants get access to their memorable photos.
                </Text>
                <Link to={"/social"}>
                  <Text
                    color={"#FFAC30"}
                    marginTop={"0.9vh"}
                  
                    fontSize={"0.8rem"}
                    cursor={"pointer"}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Read More...
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Stack>
        </Flex>
      </Box>

      <Box
        margin={"auto"}
        width={["100vw", "100vw", "80vw", "65vw"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        mt={32}
      >
        <Box
          borderRadius={10}
          py={3}
          px={4}
          bg="white"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          color="black"
          m={2}
          h={{ base: "43vh", md: "28vh", lg: "28vh" }}
          w={{ base: "99vw", md: "25vw", lg: "23vw" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={{ base: "20px", md: "0" }}
        >
          <Image src={img13} alt="Centered Image" />
          <Text
            color="#000000"
            fontSize="1.1rem"
            fontWeight="700"
            textAlign="center"
          >
            Create event and invite Guest
          </Text>
          <Text textAlign="center" fontSize="0.8rem">
            Create an event, upload photos
            <br /> and invite all guests
          </Text>
        </Box>
        <Box
          borderRadius={10}
          py={3}
          px={4}
          bg="white"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          color="black"
          m={2}
          h={{ base: "43vh", md: "28vh", lg: "28vh" }}
          w={{ base: "99vw", md: "25vw", lg: "23vw" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={{ base: "20px", md: "0" }}
        >
          <Image src={img14} alt="Centered Image" />
          <Text
            
            color="#000000"
            fontSize="1.1rem"
            fontWeight="700"
            textAlign="center"
          >
            Click a selfie to find photos
          </Text>
          <Text textAlign="center"  fontSize="0.8rem">
            Guest opens the link & clicks a
            <br /> selfie to find their photos
          </Text>
        </Box>
        <Box
          borderRadius={10}
          py={3}
          px={4}
          bg="white"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          color="black"
          m={2}
          h={{ base: "43vh", md: "28vh", lg: "28vh" }}
          w={{ base: "99vw", md: "25vw", lg: "23vw" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={{ base: "20px", md: "0" }}
        >
          <Image src={img15} alt="Centered Image" />
          <Text
            color="#000000"
            fontSize="1.1rem"
            fontWeight="700"
            textAlign="center"
          >
            Get Your photos
          </Text>
          <Text textAlign="center"  fontSize="0.8rem">
            Guests can view, buy,
            <br /> download & share photos
          </Text>
        </Box>
      </Box>

      <Box width={"100vw"} height={"85vh"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <HStack
          width={["80vw", "80vw", "90vw", "90vw"]}
          height={"100%"}
          margin={"0px auto"}
          justifyContent={"space-between"}
          alignItems={'center'}
        >
          <Image
            src={img16}
            height={["100%", "100%", "70%", "90%"]}
            alignSelf={'center'}
            width={["45%", "45%", "50%", "45%"]}
            display={["none", "none", "block", "block"]}
          ></Image>

          <Box width={["100%", "100%", "45%", "45%"]}>
            <Heading fontSize={["2.5rem", "2.5rem", "2.5rem", "3rem"]}>
              Face Recognition Powered Sharing
            </Heading>

            <Text fontSize={"1.4rem"} marginTop={"3vh"} fontWeight={"500"}>
              Click a Selfie to find your photos instantly
            </Text>

            <Text
              color={"#3c435b"}
              fontSize={"1.4rem"}
              fontWeight={"500"}
              marginTop={"3vh"}
              width={"90%"}
            >
              New way to distribute Photos - Easy, Private and Fast.
            </Text>

            <HStack
              height={"40%"}
              width={"90%"}
              marginTop={"5vh"}
              justifyContent={"space-between"}
            >
              <Flex
                height={"100%"}
                width={"40%"}
                justifyContent={"space-between"}
              >
                <Image src={img17} height={"80%"} width={"45%"}></Image>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"45%"}
                >
                  <Box>
                    <Text
                      fontSize={"1.9rem"}
                      fontWeight={"bold"}
                      marginLeft={["none", "none", "3vw", "none"]}
                    >
                      99.3%
                    </Text>
                    <Text
                      fontSize={"1rem"}
                      marginLeft={["none", "none", "3vw", "none"]}
                    >
                      Accuracy
                    </Text>
                  </Box>
                </Box>
              </Flex>

              <Flex
                height={"100%"}
                width={"40%"}
                justifyContent={"space-between"}
              >
                <Image src={img18} height={"80%"} width={"35%"}></Image>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"55%"}
                >
                  <Box>
                    <Text fontSize={"1.9rem"} fontWeight={"bold"}>
                      100%
                    </Text>
                    <Text fontSize={"1rem"}>Secure</Text>
                  </Box>
                </Box>
              </Flex>
            </HStack>

            <Button
              margin={"0px auto"}
              marginTop={"5vh"}
              width={"50%"}
              fontWeight={"400"}
              backgroundColor={"#3F115B"}
              color={"white"}
              _hover={{
                color: "black",
                border: "2px solid #3F115B",
                backgroundColor: "white",
              }}
              onClick={()=>{navigate('/newevent')}}
            >
              Create New Event
            </Button>
          </Box>
        </HStack>
      </Box>
    </div>
  );
};

export default Home;
