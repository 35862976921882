import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";
import img from "../../Assests/social_banner.png"
import img1 from "../../Assests/social1.png"
import img2 from "../../Assests/social2.png"
import img3 from "../../Assests/social3.png"
import img4 from "../../Assests/social4.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"
const Social = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>

      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
           
            <Heading>
            Capture Every Social Moment


            </Heading>
            <Text fontSize={"0.9rem"}>
              Social events are filled with joyous occasions and memorable
              interactions that deserve to be captured and cherished. Our
              website allows event managers to upload all photos from various
              social events, ensuring that every special moment is documented.
              From birthday parties and family reunions to community gatherings
              and charity events, our platform makes it easy to preserve these
              moments for all participants to enjoy.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Simple Photo Sharing
            </Heading>
            <Text fontSize={"0.9rem"}>
              Sharing photos from social events with friends, family, and
              attendees can often be a hassle. Our solution simplifies this
              process by generating a unique link for each event. Participants
              can easily access all the event photos by clicking on the provided
              link, eliminating the need for cumbersome emails or physical
              copies. This ensures that everyone can relive and share the joy of
              the event effortlessly.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Personalized Experience
            </Heading>
            <Text fontSize={"0.9rem"}>
              Our website offers a customized experience for event managers and
              attendees. Photos can be organized into albums such as “Family
              Portraits,” “Candid Moments,” “Group Activities,” and
              “Highlights,” making it easy for users to find and enjoy their
              favorite memories. Attendees can download high-resolution images
              or order prints directly from the site, providing them with
              cherished keepsakes of their social gatherings. This personalized
              approach ensures that every aspect of photo sharing is tailored to
              your event.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading>Secure and Private</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
            We understand the importance of privacy and security when it comes
            to sharing photos from social events.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
                Our platform employs advanced security measures to protect your
                images, ensuring that only authorized individuals can access
                them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
              Each unique link offers secure, password-protected access, ensuring the safety of your cherished memories. This feature provides peace of mind by restricting access to authorized users only.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
                Trust us to handle your event photos with care and
                confidentiality, allowing you to focus on enjoying the moments
                that matter.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Social;
