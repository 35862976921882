import React from 'react'
import {
  Box,
  MenuList,
  Flex,
  Text,
  Heading,
  Button,
  Link,
  Image,
  Container,
  Grid,
  GridItem,
  Stack,
  VStack,
  HStack,
  Input,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Progress,
  ChakraProvider,
  ColorModeScript,
  MenuItem,
} from '@chakra-ui/react';
import { Icon , Menu , MenuButton} from '@chakra-ui/react'
import { FaBars, FaTimes } from 'react-icons/fa'; 
import { IoIosArrowDown } from 'react-icons/io';
import { FaPhone } from 'react-icons/fa';
import { useDisclosure } from '@chakra-ui/react';
import { BiPhone , BiMailSend} from 'react-icons/bi';
import { PhoneIcon, AddIcon, WarningIcon ,LockIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { HamburgerIcon } from '@chakra-ui/icons';


import img1 from '../Assests/Logonew.png'
const Header = () => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();


  
  return (
    <div>
   <Box width={'100vw'} height={['20vh','18vh','15vh','15vh']} boxShadow= {'0px 10px 20px rgba(0, 9, 40, 0.1)'}>
       
       <Box width={'100%'} height={'28%'} backgroundColor={'#2C3038'} fontFamily={'poppins'}>
           <Box display={'flex'} width={['100%','80%']} margin={'0px auto'} height={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
       

              
               <Icon as={BiPhone} boxSize={23} margin={'auto 0px'} color={'blue'}/>
              <Text color={'white'} width={['30vw','none']} marginLeft={['1vw','1vw']} fontSize={['0.9rem','1rem']}>+91 - 9582856291</Text>
              <Icon as={ BiMailSend} marginLeft={['15vw','3vw']} boxSize={23} margin={'auto 0px'} color={'blue'}/>
              <Text fontSize={['0.9rem','1rem']} color={'white'} marginLeft={['1vw','1vw']}>picslux04@gmail.com</Text>

              
              

              

               
           


           </Box>
          


       </Box>
       <Box  width={'100%'} fontSize={'1rem'} color={'#000000'} fontFamily={'Poppins'} position={'relative'}  height={'72%'}  display={['none','none','block','block']}>



       <Stack
margin={'0px auto'}
       height={'100%'}
       spacing={8}
      
       align={['start','start','center',"center"]}
       width={'80%'}
       justify={["center", "space-between", "center", "space-between"]}
       direction={["column", "row", "row", "row"]}
       pt={[4, 4, 0, 0]}
       
     >
      
       <Image   src={img1} width={['60%','60%','15%','15%']} height={'100%'} cursor={'pointer'} onClick={()=>{navigate('/')}}></Image>
     
       <Box     spacing={8} fontFamily={'inter'} marginLeft={'10vw'}>

        
       


    
      

        
       <Menu >
       <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}  href="/" >
         Home
       </MenuButton>
      

       {/* <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}  href="/about" >
        About
       </MenuButton>
       */}

      

       <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}    onClick={() => navigate("/Faqs")} >
         FAQ
       </MenuButton>
       <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}    onClick={() => navigate("/Pricing")} >
         Pricing
       </MenuButton>
       <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}    onClick={() => navigate("/ContactUs")} >
         Get In Touch
       </MenuButton>
      
     
<Menu>
       <MenuButton color={'black'} textDecoration={'none'} marginRight={'2.5vw'} fontSize={'1.1rem'} as={Link}  cursor={'pointer'} >
         Use For { IoIosArrowDown}
       </MenuButton>

       <MenuList>
        <MenuItem onClick={()=>{navigate('/Wedding')}}>Wedding</MenuItem>
        <MenuItem onClick={() => {
                      navigate("/marathon");
                    }}>Marathons, Cyclothons and Triathlons    </MenuItem>
        <MenuItem onClick={() => {
                      navigate("/convocation");
                    }}>Convocations</MenuItem>
        <MenuItem  onClick={() => {
                      navigate("/school");
                    }}> Schools and colleges</MenuItem>
        <MenuItem  onClick={() => {
                      navigate("/event");
                    }}>Corporate Events</MenuItem>
        <MenuItem onClick={() => {
                      navigate("/event");
                    }}>Social Club</MenuItem>
       </MenuList>
       </Menu>

    

      


     </Menu>



        

         
       </Box>

       <Box display={'flex'} width={['100vw','70vw','25vw','16vw']}  justifyContent={['center','center','space-between','space-between']} alignItems={'center'}>
      
      <Button  fontWeight={'200'} width={'80%'} backgroundColor={'#3F115B'} color={'white'} margin={'auto'} _hover={{color:'black',border:'2px solid #3F115B',backgroundColor:'white'}} onClick={()=>{navigate('/newevent')}}>Create New Event</Button>
     

     </Box>


     </Stack>


       </Box>


       <Box width={'100%'} fontSize={'1rem'} color={'#000000'}  position={'relative'}  height={'72%'} display={['block','block','none','none']}>


<Flex height={'70%'} alignItems={'center'} justifyContent={'space-between'} width={'90%'} margin={'0px auto'}> 
       <Image   src={img1} width={['60%','60%','15%','15%']}  cursor={'pointer'} onClick={()=>{navigate('/')}}></Image>

       <Menu>
       <MenuButton
    as={IconButton}
    aria-label='Options'
    icon={<HamburgerIcon />}
    variant='outline'
  />


  <MenuList>
    <MenuItem onClick={()=>{navigate('/')}}>Home</MenuItem>
    <MenuItem onClick={()=>{navigate('/about')}}>About</MenuItem>
    <MenuItem>FAQ</MenuItem>
   
  </MenuList>


       </Menu>

       </Flex>


       <Box display={'flex'} width={['100vw','70vw','25vw','16vw']}  justifyContent={['center','center','space-between','space-between']} alignItems={'center'}>
      
      <Button  fontWeight={'200'} width={'80%'} backgroundColor={'#3F115B'} color={'white'} margin={'auto'} _hover={{color:'black',border:'2px solid #3F115B',backgroundColor:'white'}} onClick={()=>{navigate('/newevent')}}>Create New Event</Button>
     

     </Box>
    
       </Box>


   



           


           </Box>
    </div>
  )
}

export default Header