import React from 'react';
import { Box, SimpleGrid, Text, Heading, VStack } from '@chakra-ui/react';

function Features () {
  return (
    <Box p={5}>
      <Heading textAlign="center" mb={10}>Compare plans and features that match your business needs.</Heading>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 5 }} spacing={5}>
        <Box p={5} shadow="md" borderWidth="1px">
          <Heading fontSize="xl" mb={4}>Free Event</Heading>
          <VStack align="start">
            <Text>No. of Sub-Events per Event: Unlimited</Text>
            <Text>Image Limit per Sub-Event: Unlimited</Text>
            <Text>Maximum Photo Size: 5 MB</Text>
            <Text>Maximum Video Size: 100 MB</Text>
            <Text>Video Support: 1080p</Text>
            <Text>Face Searches: ✔</Text>
            <Text>WhatsApp Bot: ✔</Text>
            <Text>WhatsApp Messages: Unlimited</Text>
            <Text>Event Updates on WhatsApp: ✔</Text>
            <Text>Photo-Sharing through WhatsApp: ✔</Text>
          </VStack>
        </Box>
        <Box p={5} shadow="md" borderWidth="1px">
          <Heading fontSize="xl" mb={4}>Mini Event</Heading>
          <VStack align="start">
            <Text>No. of Sub-Events per Event: Unlimited</Text>
            <Text>Image Limit per Sub-Event: Unlimited</Text>
            <Text>Maximum Photo Size: 50 MB</Text>
            <Text>Maximum Video Size: 5 GB</Text>
            <Text>Video Support: 4K</Text>
            <Text>Face Searches: ✔</Text>
            <Text>WhatsApp Bot: ✔</Text>
            <Text>WhatsApp Messages: Unlimited</Text>
            <Text>Event Updates on WhatsApp: ✔</Text>
            <Text>Photo-Sharing through WhatsApp: ✔</Text>
          </VStack>
        </Box>
        <Box p={5} shadow="md" borderWidth="1px" bg="pink.50">
          <Heading fontSize="xl" mb={4}>Small Event</Heading>
          <VStack align="start">
            <Text>No. of Sub-Events per Event: Unlimited</Text>
            <Text>Image Limit per Sub-Event: Unlimited</Text>
            <Text>Maximum Photo Size: 50 MB</Text>
            <Text>Maximum Video Size: 5 GB</Text>
            <Text>Video Support: 4K</Text>
            <Text>Face Searches: ✔</Text>
            <Text>WhatsApp Bot: ✔</Text>
            <Text>WhatsApp Messages: Unlimited</Text>
            <Text>Event Updates on WhatsApp: ✔</Text>
            <Text>Photo-Sharing through WhatsApp: ✔</Text>
          </VStack>
        </Box>
        <Box p={5} shadow="md" borderWidth="1px">
          <Heading fontSize="xl" mb={4}>Mid-Sized Event</Heading>
          <VStack align="start">
            <Text>No. of Sub-Events per Event: Unlimited</Text>
            <Text>Image Limit per Sub-Event: Unlimited</Text>
            <Text>Maximum Photo Size: 50 MB</Text>
            <Text>Maximum Video Size: 5 GB</Text>
            <Text>Video Support: 4K</Text>
            <Text>Face Searches: ✔</Text>
            <Text>WhatsApp Bot: ✔</Text>
            <Text>WhatsApp Messages: Unlimited</Text>
            <Text>Event Updates on WhatsApp: ✔</Text>
            <Text>Photo-Sharing through WhatsApp: ✔</Text>
          </VStack>
        </Box>
        <Box p={5} shadow="md" borderWidth="1px">
          <Heading fontSize="xl" mb={4}>Large Event</Heading>
          <VStack align="start">
            <Text>No. of Sub-Events per Event: Unlimited</Text>
            <Text>Image Limit per Sub-Event: Unlimited</Text>
            <Text>Maximum Photo Size: 50 MB</Text>
            <Text>Maximum Video Size: 5 GB</Text>
            <Text>Video Support: 4K</Text>
            <Text>Face Searches: ✔</Text>
            <Text>WhatsApp Bot: ✔</Text>
            <Text>WhatsApp Messages: Unlimited</Text>
            <Text>Event Updates on WhatsApp: ✔</Text>
            <Text>Photo-Sharing through WhatsApp: ✔</Text>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Features;
