import React from "react";
import {
  ChakraProvider,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  Icon,
  AccordionPanel,
  Text,
  Heading,
  Button,
  Input,
  Stack,
  VStack,
  HStack,
  Grid,
  Flex,
  Container,
  IconButton,
  Badge,
  Avatar,
  Tooltip,
  Collapse,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Progress,
  Spinner,
  Image,
  Link,
  List,
  ListItem,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Tag,
  TagLabel,
  TagIcon,
  TagCloseButton,
  SimpleGrid,
  AspectRatio,
  Wrap,
  WrapItem,
  Divider,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Skeleton,
  VisuallyHidden,
  useDisclosure,
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
  withDefaultBreakpoints,
  withDefaultMotion,
  CSSReset,
  ColorModeScript,
} from "@chakra-ui/react";

const Faq = () => {
  const faqData = [
    {
      question:
        "What is picslux ?",
      answer:
        "Picslux is a state-of-the-art facial recognition software designed to capture and retrieve event photos using facial recognition technology. It offers seamless and secure access to photos by simply scanning your face.",
    },
    {
      question: "How does picslux work?",
      answer:
        "Our software uses advanced facial recognition algorithms to identify individuals in photos. Users can upload a selfie, and our system will match it with photos taken at the event, allowing them to easily find and download their pictures.",
    },
    {
      question:
        "Who can use picslux?",
      answer:
        "Our software is ideal for event organizers, companies, and individual users who want a quick and secure way to access and share event photos.",
    },
    {
      question: "What are the system requirements for using picslux ?",
      answer:
        "Picslux is compatible with all major web browsers and requires an internet connection. For best performance, we recommend using the latest version of Chrome, Firefox, Safari, or Edge.",
    },
    {
      question: "How accurate is the facial recognition technology?",
      answer:
        "Our software uses the latest facial recognition algorithms with high accuracy rates. However, the accuracy can vary based on factors such as lighting, camera quality, and facial expressions.",
    },
    {
      question: "7. Will my photos be shared without my consent?",
      answer:
        "No, your photos will only be accessible to you and authorized personnel. We do not share your photos with third parties without your explicit consent.",
    },
    {
      question: "How do I upload my photo for recognition?",
      answer:
        "Ensure that the photo you uploaded is clear and well-lit. If the problem persists, please contact our support team for assistance.",
    },
    {
      question: "Can I integrate picslux into my own platform?",
      answer:
        "Yes, we offer API and SDK integration options for businesses looking to incorporate our facial recognition technology into their own platforms. Contact us for more details.",
    },
    {
      question: "11. How can I purchase or subscribe to picslux?",
      answer:
        "Please visit our pricing page for subscription options and contact our sales team for bulk purchases or custom plans.",
    },
    {
      question: "What support do you offer for businesses using your software?",
      answer: "We provide comprehensive support including setup assistance, technical support, and regular updates. Our dedicated support team is available to help with any issues or questions.",
    },
    {
      question: "Is picslux compliant with data protection laws?",
      answer:
        "Yes, we comply with all relevant data protection laws and regulations, including GDPR and CCPA. We ensure that all user data is handled responsibly and securely.",
    },
    {
        question: "What happens to my data after the event?",
        answer:
          "Users have control over their data and can choose to delete their information at any time. Event photos are stored securely and can be accessed or removed as per the user's request.",
      },
      {
        question: "How can I provide feedback or report an issue?",
        answer:
          "We value your feedback. Please contact our support team through the contact form on our website or email us directly at picslux04@gmail.com.",
      },
      {
        question: "Where can I find more information or get help with picslux?",
        answer:
          "Visit our Help Center for detailed guides, tutorials, and FAQs. For additional support, reach out to our customer service team.",
      },
  ];

  return (
    <Box>
      <Box width={"100vw"} margin={"0px auto"}>
        <Box as="section" py={6} px={4} margin="10">
          <Heading as="h2" size="lg" textAlign="center" marginBottom={"5vh"}>
            Frequently Asked Questions
          </Heading>
          <Accordion>
            {faqData.map((item) => (
              <AccordionItem key={item.question}>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    fontWeight={"bold"}
                    marginTop={"3vh"}
                  >
                    {item.question}
                  </Box>
                  <Icon name="chevron-down" iconColor="gray.500" />
                </AccordionButton>
                <AccordionPanel>
                  <Text color={"rgb(63, 17, 91)"}>{item.answer}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
