import React, { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { HStack, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Button,
  Link,
  Image,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import MatchedPhotosPage from "./MatchedPhotosPage";
import img1 from "../../Assests/Group 4.png";
import img2 from "../../Assests/rightbg.png";
import img3 from "../../Assests/leftimag1.png";
import img4 from "../../Assests/leftimage2.png";
import "./fontGoogle.css"
const Userpage = () => {
  const [loading, setLoading] = useState(false);

  const { shareLink } = useParams();

  const [apiResponse, setApiResponse] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const imageContainerRef = useRef(null);

  const [imageSource, setImageSource] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [sseEstablished, setSSEEstablished] = useState(false);
  const [loadImages, setLoadImages] = useState(true);

  const formdata = new FormData();

  formdata.append("selfie", selfie);

  const handleSubmit = () => {
    setLoading(true);

    axios
      .post(`https://api.picslux.com/api/user/${shareLink}`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data);
        setApiResponse(response.data.matchingPhotosurl);
        console.log(imageSource);
        console.log(response.data);

        // After successfully submitting the form data, establish SSE connection
        setSSEEstablished(true);
      })
      .then((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Establish SSE connection only if it hasn't been established yet and POST request was successful
    if (sseEstablished) {
      const eventSource = new EventSource(
        `https://api.picslux.com/api/user/${shareLink}`
      );

      console.log("useeffect started");
      eventSource.onmessage = (event) => {
        const imageUrl = JSON.parse(event.data).imageUrl;

        renderImage(imageUrl);
        console.log();
        setImageUrl(imageUrl);
      };

      eventSource.onerror = (error) => {
        console.error("Error with SSE:", error);
        setLoading(false);
        eventSource.close();
        setLoadImages(false);
      };

      eventSource.onclose = () => {
        setLoading(false);
        setLoadImages(false);

        eventSource.close(); // Close the SSE connection after receiving all images
      };

      // Cleanup function to close the SSE connection when the component unmounts
      return () => {
        eventSource.close();
      };
    }
  }, [sseEstablished, shareLink]);

  const establishSSEConnection = () => {
    const eventSource = new EventSource(
      `https://api.picslux.com/api/user/${shareLink}`
    );

    eventSource.onmessage = (event) => {
      const imageUrl = JSON.parse(event.data).imageUrl;

      renderImage(imageUrl);

      setImageUrl(imageUrl);
    };

    eventSource.onerror = (error) => {
      console.error("Error with SSE:", error);
      setLoading(false); // Stop loading spinner if there's an error
      // Handle SSE error if needed
    };

    eventSource.onclose = () => {
      setLoadImages(false);
      eventSource.close();
      // Close the SSE connection after receiving all images
    };
  };

  const downloadImage = (url, filename) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Trigger a click event to initiate download
    link.click();
  };

  const renderImage = (imageUrl) => {
    const wrapper = document.createElement("div");
    wrapper.style.marginBottom = "20px";
    wrapper.style.display = "flex";
    wrapper.style.flexDirection = "column";
    wrapper.style.alignItems = "center";

    const img = document.createElement("img");
    img.src = imageUrl;
    img.style.marginBottom = "10px"; // Add space between image and button
    img.alt = "Matched Image";

    const downloadButton = document.createElement("button");
    downloadButton.innerText = "Download";
    downloadButton.style.backgroundColor = "blue";
    downloadButton.style.color = "white";
    downloadButton.style.border = "none";
    downloadButton.style.borderRadius = "6px";
    downloadButton.style.height = "40px"; // Set button height
    downloadButton.style.display = "inline-flex"; // Ensure button is inline with image
    downloadButton.style.alignItems = "center"; // Align button content vertically
    downloadButton.style.justifyContent = "center"; // Align button content horizontally
    downloadButton.style.cursor = "pointer";
    downloadButton.style.padding = "0px 10px";
    downloadButton.addEventListener("click", () =>
      downloadImage(imageUrl, "matched_image.png")
    );

    wrapper.appendChild(img);
    wrapper.appendChild(downloadButton);

    imageContainerRef.current.appendChild(wrapper);
    setLoading(false);

    // Apply different image width based on screen size
    if (wrapper.innerWidth <= "768px") {
      // Small screens
      img.style.width = "100%";
    } else {
      // Larger screens
      img.style.width = "40%";
    }
  };

  return (
    <div>
      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(0, 0, 0, 0.8)"
          zIndex="999"
        >
          <Spinner
            thickness="10px"
            speed="0.65s"
            emptyColor="purple.200"
            color="teal"
            size="xl"
          />
        </Box>
      )}

      <Box
        width={"100vw"}
        height={["95vh", "95vh", "70vh", "80vh"]}
        display={"flex"}
        justifyContent={"center"}
        color={"white"}
        marginTop={["10vh", "10vh", "none", "none"]}
      >
        <Flex
          width={["87vw", "87vw", "90vw", "65vw"]}
          height={["70vh", "70vh", "45vh", "65vh"]}
          borderRadius={"12px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            width={"50%"}
            display={["none", "none", "block", "block"]}
            height={"100%"}
          >
            <Image src={img1} width={"100%"} height={"100%"}></Image>
          </Box>

          <Box
            width={["100%", "100%", "50%", "50%"]}
            borderRadius={"0px 11px 11px 0px"}
            height={"100%"}
            backgroundColor={"#3F115B"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"white"}
            flexDirection={"column"}
            background={"linear-gradient(180deg, #420865 0%, #701AA4 100%)"}
            borderWidth={"5px 5px 5px 0px"}
            boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            position={"relative"}
          >
            <Heading
              color={"white"}
              fontSize={"2.4rem"}
              marginTop={"5vh"}
              fontWeight={"200"}
              textAlign={"center"}
              className="italiana-regular"
            >
              Upload Your Selfie
            </Heading>

            <Box
              display={"flex"}
              justifyContent={"space-around"}
              margin={"0px auto"}
              width={"80%"}
              marginTop={"4vh"}
              outline={"none"}
              border={"none"}
              _hover={{ border: "none", outline: "none" }}
              color={"black"}
            >
              <FormControl
                isRequired
                width={"100%"}
                outline={"none"}
                border={"none"}
                _hover={{ border: "none", outline: "none" }}
              >
                <FormLabel color={"#FFAC30"} fontWeight={"500"}>
                  {" "}
                  Upload Photos
                </FormLabel>
                <Input
                  py={1}
                  type="file"
                  onChange={(event) => {
                    setSelfie(event.target.files[0]);
                  }}
                  backgroundColor={"white"}
                />
              </FormControl>

              <Image
                src={img3}
                position={"absolute"}
                top={"0px"}
                left={"0px"}
              ></Image>
              <Image
                src={img4}
                position={"absolute"}
                right={"0px"}
                bottom={"0px"}
              ></Image>
            </Box>

            <Button
              margin={"0px auto"}
              marginTop={"5vh"}
              width={"50%"}
              fontWeight={"500"}
              backgroundColor={"#FFAC30"}
              color={"black"}
              _hover={{
                color: "black",
                border: "2px solid #3F115B",
                backgroundColor: "white",
              }}
              borderRadius={"full"}
              onClick={handleSubmit}
            >
              Get Photos
            </Button>
          </Box>
        </Flex>
      </Box>

      <Box marginTop="8" ref={imageContainerRef} />

      <Box
        width={"100vw"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box display={"flex"}>
          <Spinner />
          <Text marginLeft={"2vh"} marginBottom={"2vh"}>
            Loading Other Images
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default Userpage;
