import React, { useState } from "react";
import {
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Image,
} from "@chakra-ui/react";

import axios from "axios";
import SuccessPage from "./SuccessPage";
import { useNavigate } from "react-router-dom";
import img1 from "../../Assests/Group 5.png";
import { Spinner, Progress } from "@chakra-ui/react";
import "./fontGoogle.css"
const Newevent = () => {
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const newFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      newFiles.push(file);
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("mobile", mobile);

    for (let i = 0; i < files.length; i++) {
      formData.append("photos", files[i]);
    }

    const uploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setUploadProgress(progress);
      },
      timeout: 3600000,
    };

    try {
      const response = await axios.post(
        "https://api.picslux.com/api/newevent",
        formData,
        uploadConfig
      );
      const { shareLink, name } = response.data;
      window.location.href = `success/${name}/${shareLink}`;
    } catch (error) {
      console.error(error);
      // Handle network errors here (e.g., display an error message to the user)
      alert("Network Error! Please check your connection and try again.");
    } finally {
      setLoading(false);
    }
  };

  console.log(uploadProgress);

  return (
    <div>
      <Box
        width={"100vw"}
        height={["95vh", "95vh", "75vh", "95vh"]}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"white"}
        marginTop={["10vh", "10vh", "none", "none"]}
      >
        <Flex
          width={["95vw", "95vw", "100vw", "65vw"]}
          height={["80vh", "80vh", "55vh", "70vh"]}
          borderRadius={"12px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            width={"50%"}
            height={"100%"}
            display={["none", "none", "block", "block"]}
          >
            <Image src={img1} width={"100%"} height={"100%"}></Image>
          </Box>

          <Box
            width={["100%", "100%", "50%", "50%"]}
            height={"100%"}
            borderRadius={["12px", "12px", "none", "none"]}
            backgroundColor={"#3F115B"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"white"}
            flexDirection={"column"}
          >
            <Heading
              color={"white"}
              fontSize={"2.4rem"}
              marginTop={"5vh"}
              fontWeight={"200"}
              textAlign={"center"}
              className="italiana-regular"
            >
              Create New Event{" "}
            </Heading>
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              margin={"0px auto"}
              width={"80%"}
              marginTop={"4vh"}
            >
              <FormControl isRequired width={"100%"}>
                <FormLabel className="dm-sans" color={"#FFAC30"}>Name of Event</FormLabel>
                <Input
                _placeholder={{color:"white"}}
                  placeholder="Enter name of event"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              margin={"0px auto"}
              width={"80%"}
              marginTop={"4vh"}
            >
              <FormControl isRequired width={"100%"}>
                <FormLabel className="dm-sans" color={"#FFAC30"}>Mobile no</FormLabel>
                <Input
                     _placeholder={{color:"white"}}
                  placeholder="Enter mobile no"
                  name="mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              margin={"0px auto"}
              width={"80%"}
              marginTop={"4vh"}
              outline={"none"}
              border={"none"}
              _hover={{ border: "none", outline: "none" }}
              color={"black"}
            >
              <FormControl
                isRequired
                width={"100%"}
                outline={"none"}
                border={"none"}
                _hover={{ border: "none", outline: "none" }}
              >
                <FormLabel color={"#FFAC30"}> Upload Photos</FormLabel>
                <Input
                py={1}
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  backgroundColor={"white"}
                />
              </FormControl>
            </Box>
            {uploadProgress > 0 && (
              <div
                style={{
                  width: "100%",
                  marginTop: "4vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ color: "white", fontSize: "lg", marginBottom: "2" }}
                >
                  Uploading {files.length} files
                </p>
                <progress value={uploadProgress} max="100"></progress>
                <p style={{ color: "white", fontSize: "lg", marginTop: "2" }}>
                  {uploadProgress}% Complete
                </p>
              </div>
            )}

            <Button
            className="dm-sans"
              margin={"0px auto"}
              borderRadius={"full"}
              marginTop={"5vh"}
              width={"55%"}
              fontWeight={"900"}
              backgroundColor={"#FFAC30"}
              color={"black"}
              _hover={{
                color: "black",
                border: "2px solid #3F115B",
                backgroundColor: "white",
              }}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

export default Newevent;
