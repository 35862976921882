import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";
import img from "../../Assests/wedding.png"
import img1 from "../../Assests/Rectangle 19.png"
import img2 from "../../Assests/Rectangle 20.png"
import img3 from "../../Assests/Rectangle 21.png"
import img4 from "../../Assests/wedding_safe.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"
const Wedding = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>

      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
              Capture Every Moment
            </Heading>
            <Text fontSize={"0.9rem"}>
              Weddings are filled with countless precious moments, and capturing
              each one is essential for preserving the memories of your special
              day. Our website allows wedding event managers to effortlessly
              upload all the photos from the wedding, ensuring that nothing is
              missed. From the bride’s walk down the aisle to the heartfelt
              toasts, every smile and tear is documented and available to share
              with guests. With a simple and user-friendly interface, preserving
              these moments has never been easier.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Share with ease
            </Heading>
            <Text fontSize={"0.9rem"}>
              After the wedding, sharing photos with all your guests can be a
              daunting task. Our platform simplifies this process by creating a
              unique link for your event. Simply share this link with your
              guests, and they will have access to all the photos from the
              wedding. No more cumbersome emails with large attachments or the
              hassle of printing and mailing photos. With just one click, your
              guests can relive the magic of your wedding day from the comfort
              of their own homes.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
              Personalize Your Experience
            </Heading>
            <Text fontSize={"0.9rem"}>
              Our website offers a personalized experience for both the event
              manager and the guests. Event managers can organize photos into
              albums or sections, such as “Ceremony,” “Reception,” and “Candid
              Moments,” making it easy for guests to find their favorite
              memories. Guests can also download high-resolution images or order
              prints directly from the site. This customizable approach ensures
              that every aspect of your wedding photo sharing is tailored to
              your needs and preferences.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading >Safe and Secure</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
            We understand the importance of privacy and security when it comes
            to sharing your wedding photos.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
                Our website employs advanced security measures to protect your
                photos and ensure that only invited guests can access them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
                Each unique link is secure and can be password protected, giving
                you peace of mind that your memories are safe.{" "}
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
                Trust us to handle your wedding photos with the care and
                attention they deserve, so you can focus on enjoying the moments
                that matter most.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Wedding;
