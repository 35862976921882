import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";
import img from "../../Assests/law_banner.png"
import img1 from "../../Assests/law1.png"
import img2 from "../../Assests/law2.png"
import img3 from "../../Assests/law3.png"
import img4 from "../../Assests/law4.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"

const Convocations = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>
      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Preserve the Achievement
            </Heading>
            <Text fontSize={"0.9rem"}>
              Convocations are momentous occasions marking significant academic
              achievements. Our website allows event managers to capture every
              proud moment by uploading all the photos from the event. From the
              ceremonial cap toss to receiving diplomas on stage, each highlight
              is documented and preserved. Our platform ensures that no special
              moment is missed, allowing graduates and their families to cherish
              these memories for years to come.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Seamless Photo Sharing
            </Heading>
            <Text fontSize={"0.9rem"}>
              Sharing photos from large convocations can be overwhelming. Our
              solution simplifies this task by creating a unique link for the
              event. Graduates, families, and friends can easily access all the
              photos by clicking on the provided link. This eliminates the need
              for cumbersome emails with large attachments or the hassle of
              distributing physical copies. With our service, sharing the joy
              and pride of convocation day has never been easier.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
              Personalized Experience
            </Heading>
            <Text fontSize={"0.9rem"}>
              Our website offers a personalized experience for both event
              managers and graduates. Photos can be organized into categories
              such as “Ceremony,” “Family Portraits,” and “Candid Moments,”
              making it easy for individuals to find their favorite memories.
              Graduates can download high-resolution images or order prints
              directly from the site, providing them with cherished keepsakes of
              their academic accomplishments. This tailored approach ensures a
              smooth and enjoyable photo-sharing experience for all.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading >Secure and Confidential</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
            We understand the importance of privacy and security when it comes
            to sharing convocation photos.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
                Our website employs advanced security measures to protect your
                photos and ensure that only invited guests can access them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
                Each unique link is secure and can be password protected,
                providing peace of mind that your memories are safe.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
                Trust us to handle your convocation photos with the utmost care,
                allowing you to focus on celebrating the achievement.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Convocations;
