import React from "react";
import { SimpleGrid, Box, Text, Button ,Table, Thead, Tbody, Tr, Th, Td, Flex} from "@chakra-ui/react";
import { FaGlassCheers } from "react-icons/fa";
import { IoServerOutline } from "react-icons/io5";
import { MdPeopleOutline } from "react-icons/md";
import { GiSandsOfTime } from "react-icons/gi";

function Pricing() {
  return (
    <div>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} // Responsive column count
        spacingX="40px"
        spacingY="20px"
        p={{ base: '40px', md: '68px' }} // Responsive padding
        mt="28px"
        justifyContent="center"
      >
        <Box
          width={{ base: "62vw", sm: "42vw", md: "26vw", lg: "22vw", xl: "16vw" }}
          height="390px"
          borderRadius="6px"
          border={'1px solid #691A99'}
          margin="auto"
        >
          <Text
            fontFamily="DM Sans"
            color="#691A99"
            fontWeight="700"
            fontSize="24px"
            ml="10px"
            mt="10px"
          >
            Free Event
          </Text>
          <Text fontFamily="DM Sans" ml="10px">
            Experience the full power of PicsLux for 1 year
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="30px"
            mt="10px"
          >
            ₹ 0
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="18px"
            mt="10px"
          >
            What you get?
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            Event{" "}
            <FaGlassCheers
              width="30px"
              height="30px"
              style={{ marginLeft: "54%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            5 GB Storage{" "}
            <IoServerOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "25%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            40 Guests{" "}
            <MdPeopleOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "35%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Year Validity{" "}
            <GiSandsOfTime
              width="30px"
              height="30px"
              style={{ marginLeft: "23%" }}
            />
          </Text>
          <Button
            border="1px solid #691A99"
            color="#691A99"
            w={{ base: "47vw", sm: '32vw', md: '20vw', lg: '13vw', xl: '10vw' }}
            ml={{ base: "13px", sm: "13px", md: "25px", lg: "13px", xl: "28px" }}
            mt={{ base: "2px", sm: "7px", md: "8px", lg: "24px", xl: "11px" }}
          >
            Know More
          </Button>
        </Box>

        {/* Other Box components go here */}
        {/* Example for the Mini Event */}
        <Box
          width={{ base: "62vw", sm: "42vw", md: "26vw", lg: "22vw", xl: "16vw" }}
          height="390px"
          borderRadius="6px"
          border={'1px solid #691A99'}
          margin="auto"
        >
          <Text
            fontFamily="DM Sans"
            color="#691A99"
            fontWeight="700"
            fontSize="24px"
            ml="10px"
            mt="10px"
          >
            Mini Event
          </Text>
          <Text fontFamily="DM Sans" ml="10px">
            Experience the full power of PicsLux for 1 Year
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="30px"
            mt="10px"
          >
            ₹ 2500{" "}
            <Text
              as="del"
              fontFamily="DM sans"
              fontSize="16px"
              color="gray"
              fontWeight="400"
            >
              5500
            </Text>
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="18px"
            mt="10px"
          >
            What you get?
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            Event{" "}
            <FaGlassCheers
              width="30px"
              height="30px"
              style={{ marginLeft: "54%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="8px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            25 GB Storage{" "}
            <IoServerOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "25%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            100 Guests{" "}
            <MdPeopleOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "35%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Year Validity{" "}
            <GiSandsOfTime
              width="30px"
              height="30px"
              style={{ marginLeft: "26%" }}
            />
          </Text>
          <Button
            border="1px solid #691A99"
            color="#691A99"
            w={{ base: "47vw", sm: '32vw', md: '20vw', lg: '13vw', xl: '10vw' }}
            ml={{ base: "13px", sm: "13px", md: "25px", lg: "34px", xl: "28px" }}
            mt={{ base: "2px", sm: "7px", md: "8px", lg: "7px", xl: "11px" }}
          >
            Know More
          </Button>
        </Box>

        {/* Additional Box components go here */}
        {/* Continue adding similar Box components for other plans */}
        {/* Example: Small Event */}
        <Box
          width={{ base: "62vw", sm: "42vw", md: "26vw", lg: "22vw", xl: "16vw" }}
          height="390px"
          borderRadius="6px"
          border={'1px solid #691A99'}
          margin="auto"
          bg={'#691A99'}
        >
          <Text
            fontFamily="DM Sans"
            color={'#FFAC30'}
            fontWeight="700"
            fontSize="24px"
            ml="10px"
            mt="10px"
          >
            Small Event
          </Text>
          <Text fontFamily="DM Sans" ml="10px" color={'white'}>
            Ideal for weddings and conferences
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="30px"
            mt="10px"
            color={'white'}
          >
            ₹ 5000{" "}
            <Text
              as="del"
              fontFamily="DM sans"
              fontSize="16px"
              color="gray"
              fontWeight="400"
            >
              5500
            </Text>
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="18px"
            mt="10px"
            color={'white'}
          >
            What you get?
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color={'#FFAC30'}
            display="flex"
          >
            1 Event{" "}
            <FaGlassCheers
              width="30px"
              height="30px"
              style={{ marginLeft: "54%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="8px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color={'#FFAC30'}
            display="flex"
          >
            50 GB Storage{" "}
            <IoServerOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "25%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color={'#FFAC30'}
            display="flex"
          >
            200 Guests{" "}
            <MdPeopleOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "35%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color={'#FFAC30'}
            display="flex"
          >
            1 Year Validity{" "}
            <GiSandsOfTime
              width="30px"
              height="30px"
              style={{ marginLeft: "26%" }}
            />
          </Text>
          <Button
            border="1px solid #691A99"
            color="#691A99"
            w={{ base: "47vw", sm: '32vw', md: '20vw', lg: '13vw', xl: '10vw' }}
            ml={{ base: "13px", sm: "13px", md: "25px", lg: "44px", xl: "28px" }}
            mt={{ base: "2px", sm: "24px", md: "8px", lg: "10px", xl: "10px" }}
          >
            Know More
          </Button>
        </Box>

        {/* Example: Mid Sized Event */}
        <Box
          width={{ base: "62vw", sm: "42vw", md: "26vw", lg: "22vw", xl: "16vw" }}
          height="390px"
          borderRadius="6px"
          border={'1px solid #691A99'}
          margin="auto"
        >
          <Text
            fontFamily="DM Sans"
            color="#691A99"
            fontWeight="700"
            fontSize="24px"
            ml="10px"
            mt="10px"
          >
            Mid Sized Event
          </Text>
          <Text fontFamily="DM Sans" ml="10px">
            Experience the full power of PicsLux for 1 Year
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="30px"
            mt="10px"
          >
            ₹ 7500{" "}
            <Text
              as="del"
              fontFamily="DM sans"
              fontSize="16px"
              color="gray"
              fontWeight="400"
            >
              15000
            </Text>
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="18px"
            mt="10px"
          >
            What you get?
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Event{" "}
            <FaGlassCheers
              width="30px"
              height="30px"
              style={{ marginLeft: "54%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="6px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            100 GB Storage{" "}
            <IoServerOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "25%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            500 Guests{" "}
            <MdPeopleOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "35%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Year Validity{" "}
            <GiSandsOfTime
              width="30px"
              height="30px"
              style={{ marginLeft: "29%" }}
            />
          </Text>
          <Button
            border="1px solid #691A99"
            color="#691A99"
            w={{ base: "47vw", sm: '32vw', md: '20vw', lg: '13vw', xl: '10vw' }}
            ml={{ base: "13px", sm: "13px", md: "25px", lg: "45px", xl: "28px" }}
            mt={{ base: "2px", sm: "7px", md: "8px", lg: "7px", xl: "10px" }}
          >
            Know More
          </Button>
        </Box>

        {/* Example: Large Event */}
        <Box
          width={{ base: "62vw", sm: "42vw", md: "28vw", lg: "22vw", xl: "16vw" }}
          height="390px"
          borderRadius="6px"
          border={'1px solid #691A99'}
          margin="auto"
        >
          <Text
            fontFamily="DM Sans"
            color="#691A99"
            fontWeight="700"
            fontSize="24px"
            ml="10px"
            mt="10px"
          >
            Large Event
          </Text>
          <Text fontFamily="DM Sans" ml="10px">
            Experience the full power of PicsLux for 1 Year
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="30px"
            mt="10px"
          >
            ₹ 10,500{" "}
            <Text
              as="del"
              fontFamily="DM sans"
              fontSize="16px"
              color="gray"
              fontWeight="400"
            >
              21000
            </Text>
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            fontWeight="700"
            fontSize="18px"
            mt="10px"
          >
            What you get?
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Event{" "}
            <FaGlassCheers
              width="30px"
              height="30px"
              style={{ marginLeft: "54%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="6px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            200 GB Storage{" "}
            <IoServerOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "25%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1000 Guests{" "}
            <MdPeopleOutline
              width="30px"
              height="30px"
              style={{ marginLeft: "35%" }}
            />
          </Text>
          <Text
            fontFamily="DM Sans"
            ml="10px"
            mt="10px"
            fontWeight="500"
            fontSize="18px"
            color="#691A99"
            display="flex"
          >
            1 Year Validity{" "}
            <GiSandsOfTime
              width="30px"
              height="30px"
              style={{ marginLeft: "29%" }}
            />
          </Text>
          <Button
            border="1px solid #691A99"
            color="#691A99"
            w={{ base: "47vw", sm: '32vw', md: '20vw', lg: '13vw', xl: '10vw' }}
            ml={{ base: "13px", sm: "13px", md: "25px", lg: "27px", xl: "28px" }}
            mt={{ base: "2px", sm: "7px", md: "8px", lg: "6px", xl: "10px" }}
          >
            Know More
          </Button>
        </Box>
      </SimpleGrid>

      <Flex
      justifyContent="center"
      alignItems="center"
      
      p={5}
    >
      <Box overflowX="auto" justifyContent={'center'}>
      <Table variant="simple" size="sm"  w={'65vw'}>
        <Thead border={'1px solid #691A99'}>
          <Tr bg={'#F8F8F8'} border={'1px solid #691A99'} >
            <Th fontSize={'1.25rem'} padding={'1.5rem'} color={'#691A99'} fontFamily={'inherit'} textTransform={'none'}>Features</Th>
            <Th fontSize={'1.25rem'} padding={'1.5rem'} color={'#691A99'}fontFamily={'inherit'} textTransform={'none'}>Free Event</Th>
            <Th fontSize={'1.25rem'} padding={'1.5rem'} color={'#691A99'} fontFamily={'inherit'} textTransform={'none'}>Mini Event</Th>
            <Th bg="#691A99" padding={'1.5rem'} color={'#FFAC30'} fontSize={'1.25rem'} fontFamily={'inherit'} textTransform={'none'}>Small Event</Th>
            <Th fontSize={'1.25rem'} padding={'1.5rem'} color={'#691A99'} fontFamily={'inherit'} textTransform={'none'}>Mid-Sized Event</Th>
            <Th fontSize={'1.25rem'} padding={'1.5rem'} color={'#691A99'} fontFamily={'inherit'} textTransform={'none'}>Large Event</Th>
          </Tr>
        </Thead>
        <Tbody border={'1px solid #691A99'}>
          <Tr  >
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>No. of Sub-Events per Event</Td>
            <Td  >Unlimited</Td>
            <Td > Unlimited</Td>
            <Td bg="purple.100">Unlimited</Td>
            <Td >Unlimited</Td>
            <Td >Unlimited</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Image Limit per Sub-Event</Td>
            <Td>Unlimited</Td>
            <Td>Unlimited</Td>
            <Td bg="purple.100">Unlimited</Td>
            <Td>Unlimited</Td>
            <Td>Unlimited</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Maximum Photo Size</Td>
            <Td>5 MB</Td>
            <Td>50 MB</Td>
            <Td bg="purple.100">50 MB</Td>
            <Td>50 MB</Td>
            <Td>50 MB</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Maximum Video Size</Td>
            <Td>100 MB</Td>
            <Td>5 GB</Td>
            <Td bg="purple.100">5 GB</Td>
            <Td>5 GB</Td>
            <Td>5 GB</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Video Support</Td>
            <Td>1080p</Td>
            <Td>4K</Td>
            <Td bg="purple.100">4K</Td>
            <Td>4K</Td>
            <Td>4K</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Face Searches</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td bg="purple.100">✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>WhatsApp Bot</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td bg="purple.100">✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>WhatsApp Messages</Td>
            <Td>Unlimited</Td>
            <Td>Unlimited</Td>
            <Td bg="purple.100">Unlimited</Td>
            <Td>Unlimited</Td>
            <Td>Unlimited</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Event Updates on WhatsApp</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td bg="purple.100">✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td fontFamily={'Open Sans, sans-serif'} color={'#691A99'} bg={'#F8F8F8'} fontSize={'1rem'}>Photo-Sharing through WhatsApp</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td bg="purple.100">✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
      </Flex>
    </div>


  );
}

export default Pricing;
