import { extendTheme } from '@chakra-ui/react';
import '@fontsource/dm-sans'; 
import '@fontsource/italiana'; 

const theme = extendTheme({
  fonts: {
    heading: 'Inter, sans-serif', // Correct key is `heading` not `Heading`
    body: 'DM Sans, sans-serif', // Corrected the spacing in the font family strings
  },
});

export default theme;
