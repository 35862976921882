import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";

import img from "../../Assests/corporate_banner.png"
import img1 from "../../Assests/corporate1.png"
import img2 from "../../Assests/corporate2.png"
import img3 from "../../Assests/corporate3.png"
import img4 from "../../Assests/corporate4.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"
const Events = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>

      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
            Capture Professional Moments
            </Heading>
            <Text fontSize={"0.9rem"}>
            Corporate events are pivotal occasions for networking, learning, and celebrating achievements. Our website allows event managers to capture and upload all photos from these events, ensuring that every important moment is documented. From keynote speeches and breakout sessions to award presentations and networking mixers, our platform ensures that every professional moment is preserved and can be shared easily.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
            Effortless Sharing
            </Heading>
            <Text fontSize={"0.9rem"}>
            Sharing photos from corporate events with attendees and stakeholders can be a daunting task. Our solution simplifies this process by creating a unique link for each event. Participants, employees, and clients can easily access all event photos by clicking on the provided link. This eliminates the need for cumbersome email attachments or physical copies, making it convenient for everyone to revisit and share the event highlights.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
            Tailored Experience
            </Heading>
            <Text fontSize={"0.9rem"}>
            Our website offers a customizable experience for both event managers and participants. Photos can be organized into categories such as “Speeches,” “Workshops,” “Awards,” and “Networking,” making it easy for users to find their specific moments of interest. Attendees can download high-resolution images or order prints directly from the site, allowing them to keep professional mementos. This tailored approach ensures that every aspect of photo sharing aligns with the needs of your corporate event.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading>Safe and Secure</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
            We understand the importance of privacy and confidentiality when it comes to corporate event photos.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
              Our platform employs advanced security measures to protect your images, ensuring that only authorized individuals can access them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
              Each unique link is secure and can be password protected, providing peace of mind that your corporate memories are safe.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
              Trust us to handle your event photos with the utmost professionalism, allowing you to focus on the success of your event.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Events;
