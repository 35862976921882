import React from 'react';
import { Box, Heading, Text, List, ListItem } from '@chakra-ui/react';

const AboutUs = () => {
  return (
    <Box p={5} width={'80%'} margin={'0px auto'}>
      <Heading as="h1" mb={4}>
        About Us
      </Heading>
      <Text mb={4}>
        <strong>World Business Review Corporation (WBR Corp ©)</strong> is a leading Brand Management Consulting & Research company renowned for its exceptional service and reputation. We specialize in offering a creative range of branding solutions to our esteemed clients, helping them evolve into prominent brands across Asia through our comprehensive corporate services. Our expertise encompasses cutting-edge online marketing, social media, PR, and media relations management.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Our Initiatives
      </Heading>
      <Text mb={4}>
        At WBR Corp, we are committed to creating awareness about social issues through unique events such as marathons and beauty pageants in India. We provide exclusive promotional platforms to our clients, leveraging our extensive networks to establish a distinct market presence.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Our Philosophy
      </Heading>
      <Text mb={4}>
        We believe in collaborative efforts to achieve common goals efficiently and effectively. Our team, with years of experience in brand building and strategic planning, ensures the growth and enhancement of your business. Innovation, values, and ethics are the core attributes driving our company.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Our Expertise
      </Heading>
      <Text mb={4}>
        Our intelligence and expertise allow us to elevate your company to new heights. We have successfully served numerous reputed companies across Asia, establishing a robust market image for them.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        About the Director
      </Heading>
      <Text mb={4}>
        <strong>Ms. Shilpa Gupta, Director of WBR Corp ©,</strong> is a seasoned professional with over 22 years of experience in Client Servicing, Marketing, Branding, Consulting, PR, Human Resources, and Publishing. Her strengths lie in people management, strong client relationships, impactful solutions, and innovation.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Our Mission
      </Heading>
      <Text mb={4}>
        Client satisfaction is our essential tool for growth and success. We believe that our success is intertwined with that of our clients. By adhering to innovative ideas, ethics, and values, we transform companies into distinguished brands. Our philosophy revolves around client satisfaction and diligent work.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Our Passion
      </Heading>
      <Text mb={4}>
        We focus on providing unique platforms that propel our clients to success. With the support of experienced professionals, we embrace innovation and position ourselves as a top-tier Brand Consulting Company. Our passion for industry transformation ensures that your brand stands out distinctly.
      </Text>
    </Box>
  );
};

export default AboutUs;
