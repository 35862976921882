import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";


import img from "../../Assests/school1.png"
import img1 from "../../Assests/school2.png"
import img2 from "../../Assests/school3.png"
import img3 from "../../Assests/school4.png"
import img4 from "../../Assests/school5.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"
const School = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>

      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Document Academic Life
            </Heading>
            <Text fontSize={"0.9rem"}>
              Schools and colleges are vibrant communities where countless
              memories are made every day. Our website allows event managers to
              capture and upload photos from various school and college events,
              ensuring that these moments are preserved. From class activities
              and sports events to graduations and award ceremonies, every
              significant moment in a student’s academic life can be documented.
              Our platform makes it simple to keep these memories alive and
              accessible.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Easy Photo Sharing
            </Heading>
            <Text fontSize={"0.9rem"}>
              Sharing photos from school and college events can be a complex
              task, especially with large student bodies and numerous events.
              Our solution streamlines this process by generating a unique link
              for each event. Students, parents, and faculty can easily access
              all event photos by clicking on the provided link. This eliminates
              the need for cumbersome distribution methods and ensures that
              everyone can enjoy the memories without hassle.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Customizable Experience
            </Heading>
            <Text fontSize={"0.9rem"}>
              Our website offers a personalized experience for both event
              managers and the school community. Photos can be categorized into
              albums such as “Classroom Activities,” “Sports Day,” “Graduation,”
              and “Cultural Events,” making it easy for users to find their
              favorite moments. Students and parents can download
              high-resolution images or order prints directly from the site,
              providing them with treasured keepsakes. This customized approach
              ensures that every aspect of photo sharing is tailored to the
              needs of the school or college.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading >Safe and Secure</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
           We prioritize the privacy and security of your school and college photos.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
              Our platform uses advanced security measures to protect your images, ensuring that only authorized individuals can access them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
            
ChatGPT
Each unique link is secure and can be password protected, ensuring the safety of your photos with controlled access. This feature provides peace of mind by limiting viewing to authorized users only, whether for personal use or professional sharing.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
              With our reliable service, you can focus on creating and celebrating memorable educational experiences while we take care of preserving and sharing the memories.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default School;
