import img from "../../Assests/marathon.png"
import img1 from "../../Assests/marathon1.png"
import img2 from "../../Assests/marathon2.png"
import img3 from "../../Assests/marathon3.png"
import img4 from "../../Assests/marathon4.png"
import img5 from "../../Assests/wedding_shield.png"
import img6 from "../../Assests/wedding_lock.png"
import img7 from "../../Assests/Group 15903.png"
import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";
const Marathons = () => {
  return (
    <>
      <Box>
        <Image src={img} alt="wedding" />
      </Box>

      <Flex
        gap={10}
        width={"80%"}
        margin={"auto"}
        py={4}
        flexDirection={"column"}
      >
        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Capture the Thrill
            </Heading>
            <Text fontSize={"0.9rem"}>
              Marathons, Cyclothons, and Triathlons are events filled with
              energy, determination, and unforgettable moments. Our website
              allows event managers to capture every exhilarating second by
              uploading all the photos from the event. From the starting line to
              the finish line, and every milestone in between, participants can
              relive the excitement and triumph of their achievements. Our
              platform makes it easy to ensure that no moment is missed,
              preserving the spirit of the race.
            </Text>
          </Flex>
          <Box>
            <Image src={img1} alt="img1" />
          </Box>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Image src={img2} alt="img1" />
          </Box>
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading >
              Effortless Sharing
            </Heading>
            <Text fontSize={"0.9rem"}>
              Sharing photos from large-scale events like marathons, cyclothons,
              and triathlons can be a logistical challenge. Our solution
              simplifies this process by generating a unique link for each
              event. Participants and spectators can easily access all event
              photos by clicking on the provided link. Forget about the hassle
              of manually distributing photos; with our service, sharing
              memories with all participants has never been more convenient or
              efficient.
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            gap={4}
            flexDirection={"column"}
            width={{ base: "100%", lg: "50%" }}
          >
            <Heading>
              Tailored to Your Event
            </Heading>
            <Text fontSize={"0.9rem"}>
              Our website provides a customized experience for event managers
              and participants alike. Photos can be organized into categories
              such as “Start,” “On the Course,” and “Finish,” making it simple
              for participants to find and enjoy their personal highlights.
              Additionally, participants can download high-resolution images or
              order prints directly from the site, allowing them to keep a
              physical reminder of their accomplishment. This tailored approach
              ensures that every aspect of photo sharing is optimized for your
              event.
            </Text>
          </Flex>
          <Box>
            <Image src={img3} alt="img1" />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} gap={10} py={5}>
        <Box textAlign={"center"}>
          <Heading >Secure and Reliable</Heading>
          <Text
            width={{ base: "80%", md: "40%" }}
            fontSize={"0.9rem"}
            margin={"auto"}
          >
            We prioritize the security and privacy of your event photos.
          </Text>
        </Box>

        <Flex
          width={"80%"}
          margin={"auto"}
          gap={6}
          fontFamily={"popins"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            width={{ base: "100%", lg: "54%" }}
          >
            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img5} />
              <Text>
                Our platform uses advanced security protocols to protect your
                images, ensuring that only authorized participants can access
                them.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img6} />
              <Text>
                Each unique link is secure and can be further protected with a
                password, giving you confidence that your photos are safe.
              </Text>
            </Flex>

            <Flex gap={5} justifyContent={"center"} alignItems={"center"}>
              <Image src={img7} />
              <Text>
                With our reliable service, you can focus on organizing an
                unforgettable event while we take care of preserving and sharing
                the memories.
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Image src={img4} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Marathons;
