import React from 'react';
import { Box, Heading, Text, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const PrivacyPolicy = () => {
  return (
    <Box p={5} width={'80%'} margin={'0px auto'}>
      <Heading as="h1" mb={4}>
        Privacy Policy
      </Heading>
      <Text mb={2}>Last updated: [Insert Date]</Text>
      <Text mb={4}>
        Welcome to Picslux. This privacy policy outlines how we collect, use, share, and retain your information, as well as your rights regarding your data.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Data Collection
      </Heading>
      <Text mb={4}>
        We may collect the following types of information:
      </Text>
      <List spacing={3} mb={4}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Name
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Email address
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Phone number
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Usage data
        </ListItem>
      </List>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        How Data is Used
      </Heading>
      <Text mb={4}>
        Your data is used for the following purposes:
      </Text>
      <List spacing={3} mb={4}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Service provision and improvement
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Communication
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Legal compliance
        </ListItem>
      </List>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Data Sharing
      </Heading>
      <Text mb={4}>
        We do not share your data with third parties, except for the following cases:
      </Text>
      <List spacing={3} mb={4}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Service providers assisting us in our services
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Legal requirements
        </ListItem>
      </List>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Data Retention
      </Heading>
      <Text mb={4}>
        We retain your data only as long as necessary to fulfill the purposes for which it was collected or to comply with legal requirements.
      </Text>
      
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Your Rights
      </Heading>
      <Text mb={4}>
        You have the following rights regarding your data:
      </Text>
      <List spacing={3} mb={4}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Access to your data
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Correction of your data
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Deletion of your data
        </ListItem>
      </List>
      
      <Text mt={6}>
        For more information or to exercise your rights, please contact us at [Insert Contact Information].
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
