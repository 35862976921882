import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Box, GridItem, background, position } from "@chakra-ui/react";
import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
  Text,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
  Image,
} from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";

import { HiOutlineMail } from "react-icons/hi";
import { FaGlobe } from "react-icons/fa";

import { FaMapMarkerAlt } from "react-icons/fa";
import { FaInstagram, FaLinkedin, FaFacebook, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import img1 from "../Assests/Logonew.png";
const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "#F0F1F5" }}>
      <Box
        className="fend"
        width={"100vw"}
        height={["90vh",'100vh','90vh', "40vh"]}
        color={"black"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box
          width={"80vw"}
          height={["130vh", "110vh", "67vh", "35vh"]}
          margin={"0px auto"}
        >
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            gap={6}
            width="60vw"
            margin={"0px auto"}
            marginTop={"7vh"}
          >
            {/* <GridItem>
    <Box width={'100%'} marginRight={'5vw'} >
                <VStack >
                    <Text style={{fontSize:'1.2rem',fontWeight:'600'}} >Top Colleges</Text>
                
                    <Text fontSize={'1rem'} color={'rgba(256,256,256,0.7)'} onClick={()=>{navigate('/colleges/All India Institute of Medical Sciences Patna')}}cursor={'pointer'} _hover={{color:"#F4903A"}}>AIIMS Patna</Text>
                    <Text fontSize={'1rem'} color={'rgba(256,256,256,0.7)'} onClick={()=>{navigate('/colleges/All India Institute of Medical Sciences Bhopal')}} cursor={'pointer'} _hover={{color:"#F4903A"}}>AIIMS Bhopal</Text>
                    <Text fontSize={'1rem'} color={'rgba(256,256,256,0.7)'}  onClick={()=>{navigate('/colleges/All India Institute of Medical Sciences Jodhpur')}} cursor={'pointer'} _hover={{color:"#F4903A"}}>AIIMS Jhodpur</Text>
                    <Text fontSize={'1rem'} color={'rgba(256,256,256,0.7)'} onClick={()=>{navigate('/colleges/All India Institute of Medical Sciences New Delhi')}} cursor={'pointer'} _hover={{color:"#F4903A"}}>AIIMS Delhi</Text>

                
                </VStack>

            </Box>
    </GridItem> */}

            <GridItem display={['none','none','block','block']}>
              <Box width={"100%"} marginRight={"5vw"}>
                <VStack alignItems={"start"} margin={"0px auto"}>
                  <Text style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                    Use Cases
                  </Text>

                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    marginTop={"2vh"}
                    onClick={() => {
                      navigate("/Wedding");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Weddings
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate("/marathon");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
Marathons, Cyclothons and Triathlons                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate("/convocation");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Convocations
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate("/school");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                Schools and collegesY
</Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate("/event");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Corporate Events
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate("/social");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Social Club
                  </Text>
                  
                </VStack>
              </Box>
            </GridItem>

            <GridItem display={['none','none','block','block']}>
              <Box width={"100%"} marginRight={"5vw"}>
                <VStack alignItems={"start"}>
                  <Text style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                    Quick Links
                  </Text>

                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate(
                        "/colleges/All India Institute of Medical Sciences Patna"
                      );
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Log in
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate(
                        "/colleges/All India Institute of Medical Sciences Bhopal"
                      );
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    Create event
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    color={"#3F115B"}
                    onClick={() => {
                      navigate(
                        "/Faqs"
                      );
                    }}
                    cursor={"pointer"}
                    
                    _hover={{ color: "#F4903A" }}
                    
                  >
                    FAQ
                  </Text>
                </VStack>
              </Box>
            </GridItem>

            <GridItem>
              <Box width={"100%"} marginRight={"5vw"}>
                <VStack alignItems={"start"}>
                  <Text style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                    Company
                  </Text>

                  {/* <Text
                    fontSize={"1rem"}
                    marginTop={"2vh"}
                    color={"rgba(256,256,256,0.7)"}
                    onClick={() => {
                      navigate("/About");
                    }}
                    cursor={"pointer"}
                    _hover={{ color: "#F4903A" }}
                  >
                    About Us
                  </Text> */}

                  <Text
                    cursor={"pointer"}
                    color={"#3F115B"}
                    textAlign={"start"}
                    onClick={()=>{navigate('/privacy-policy')}}
                  >
                    Privacy Policy
                  </Text>
                </VStack>
              </Box>
            </GridItem>

            <GridItem>
              <Box width={"100%"} marginRight={"5vw"} >
                <VStack alignItems={"start"}>
                  <Image src={img1}></Image>

                  

                  <Flex marginTop={"3vh"}>
                    <Icon
                      as={FontAwesomeIcon}
                      icon={faPhone}
                      color="green.500"
                      boxSize={6}
                      ml={4}
                    />
                    <Text color={"#3F115B"} ml={2}>+91 - 9582856291</Text>
                  </Flex>

                  <Flex marginTop={"3vh"}>
                    <Icon
                      as={FontAwesomeIcon}
                      icon={faEnvelope}
                      color="red.500"
                      boxSize={6}
                      ml={4}
                    />
                    <Text  color={"#3F115B"}ml={2}>picslux04@gmail.com</Text>
                  </Flex>
                </VStack>
              </Box>
            </GridItem>
          </Grid>
        </Box>

        <Box
          height={"20vh"}
          display={"flex"}
          margin={"0px auto"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* <Text>
            Developed By{" "}
            <span style={{ color: "yellow" }}>
              {" "}
              &nbsp;ARV Multimedia Pvt. Ltd.
            </span>
          </Text> */}
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
